import {
  GetOpportunitiesSortKey,
  Opportunity,
  OpportunityStatus,
  SortDirection,
} from "generated/graphql";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import OpportunityActionsCell from "modules/Opportunities/OpportunityActionsCell/OpportunityActionsCell";
import { Box } from "@mui/material";
import { useCallback, useMemo, useEffect } from "react";
import { Cell } from "react-table";
import useGetOpportunities from "./useGetOpportunities";
import { Link } from "react-router-dom";
import { sortDirectionMap } from "constants/sortDirectionMap";
import MultipleChoiceFilter from "components/filters/MultipleChoiceFilter/MultipleChoiceFilter";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { equals, reject, uniq } from "ramda";
import SingleChoiceFilter from "components/filters/SingleChoiceFilter/SingleChoiceFilter";
import useGetAllOrganizationUsersNonEmployee from "hooks/users/useGetAllOrganizationUsersNonEmployee";
import useGetAllCompanies from "hooks/companies/useGetAllCompanies";
import DateFilter from "components/filters/DateFilter/DateFilter";
import MDBox from "components/MDBox";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { capitalCase } from "change-case";
import { shortenLongDescription, stripHtml } from "utils/strings/shortenLongDescription";
import { useGetCompanyLazy } from "hooks/companies/useGetCompanyLazy";

export const opportunitiesSortKeyMap = {
  id: GetOpportunitiesSortKey.ID,
  name: GetOpportunitiesSortKey.NAME,
  addressLine1: GetOpportunitiesSortKey.ADDRESS,
  contact: GetOpportunitiesSortKey.CONTACT,
  company: GetOpportunitiesSortKey.COMPANY,
  status: GetOpportunitiesSortKey.STATUS,
  "company.name": GetOpportunitiesSortKey.COMPANY,
  "contact.firstName": GetOpportunitiesSortKey.CONTACT,
  dueAt: GetOpportunitiesSortKey.DUEAT,
};

export const opportunitiesInitialSortDirectionKey = "asc";
export const opportunitiesInitialSortDirectionValue = SortDirection.ASC;
export const opportunitiesInitialSortKeyKey = "id";
export const opportunitiesInitialSortKeyValue = GetOpportunitiesSortKey.ID;

const Search = ({ defaultValue, onQueryChange }) => {
  return (
    <MDBox position="relative" zIndex={2}>
      <MDBox display="flex" alignItems="center" flexWrap="wrap">
        <Paper
          component="form"
          sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, mr: 2 }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputBase
            autoFocus
            sx={{ ml: 1, flex: 1, fontSize: "14px" }}
            placeholder="Search Opportunities"
            value={defaultValue}
            inputProps={{ "aria-label": "search opportunities" }}
            onChange={({ currentTarget }: any) => {
              onQueryChange(currentTarget.value);
            }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </MDBox>
    </MDBox>
  );
};

export default function useOpportunitiesTable({
  companyId,
}: {
  companyId?: string;
} = {}) {
  let savedFilters = JSON.parse(localStorage.getItem("useOpportunitiesTableFilters")) ?? null;
  if (companyId) {
    // For companies view
    savedFilters = {};
  }
  const { filtering, sorting, offset } = usePaginatedVariables({
    initialCompanyId: companyId ?? null,
    initialStatuses: savedFilters?.statuses,
    initialMultipleCompanyIds: savedFilters?.multipleCompanyIDs,
    initialContactName: savedFilters?.contactName,
    initialDueAt: savedFilters?.dueAt,
    initialSearchTerm: savedFilters?.searchTerm ?? null,
    initialMultipleUserIDs: savedFilters?.multipleUserIDs ?? null,
  });

  const params = {
    page: offset.page,
    first: offset.first,
    search: filtering.debouncedSearchTerm,
    sort: sortDirectionMap[sorting.sortDirection],
    sortKey: opportunitiesSortKeyMap[sorting.sortKey] || opportunitiesInitialSortKeyValue,
    sortDirection:
      sortDirectionMap[sorting.sortDirection] || opportunitiesInitialSortDirectionValue,
    statuses: filtering.statuses as OpportunityStatus[],
    dueAt: filtering.dueAt,
    userId: filtering.userID,
    companyId: filtering.companyId,
    multipleUserIDs: filtering.multipleUserIDs,
    multipleCompanyIDs: filtering.multipleCompanyIDs,
    contactName: filtering.contactName,
  };

  useEffect(() => {
    localStorage.setItem("useOpportunitiesTableFilters", JSON.stringify(filtering));
  }, [filtering]);

  const [getCompany, { company }] = useGetCompanyLazy();

  // getting single company here cos the company might not be in the first 250 (default)
  // list if the org has loads of companies
  useEffect(() => {
    if (filtering.companyId) {
      getCompany({ id: filtering.companyId });
    }
  }, [filtering.companyId]);

  const { data, loading, error, paginatorInfo, refetch } = useGetOpportunities(params);
  const {
    loading: organizationUsersLoading,
    data: organizationUsersData,
    error: organizationUsersError,
  } = useGetAllOrganizationUsersNonEmployee();

  const {
    loading: companiesLoading,
    data: companiesData,
    error: companiesError,
  } = useGetAllCompanies();

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const searchControl = (
    <Search defaultValue={filtering.searchTerm} onQueryChange={filtering.setSearchTerm} />
  );

  const handleStatusChecked = useCallback(
    (selectedOptions) => {
      return filtering.setStatuses(selectedOptions);
    },
    [filtering]
  );

  const StatusFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.statuses}
          options={enumToValueOptions(OpportunityStatus)}
          onChange={handleStatusChecked}
        />
      );
    },
    [filtering.statuses]
  );

  const handleSalesPersonChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleUserIDs(selectedOptions);
    },
    [filtering]
  );

  const handleClientChecked = useCallback(
    (selectedOptions) => {
      return filtering.setMultipleCompanyIds(selectedOptions);
    },
    [filtering]
  );

  const SalesPersonFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={id}
          value={filtering.multipleUserIDs}
          loading={organizationUsersLoading}
          error={organizationUsersError}
          options={
            organizationUsersData?.map((user) => ({
              label: user.name,
              value: user.id,
            })) ?? []
          }
          onChange={handleSalesPersonChecked}
        />
      );
    },
    [
      organizationUsersData,
      filtering.multipleUserIDs,
      organizationUsersLoading,
      organizationUsersError,
    ]
  );

  const CompanyFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <MultipleChoiceFilter
          id={"company.name"}
          value={filtering.multipleCompanyIDs}
          loading={companiesLoading}
          error={companiesError}
          options={
            companiesData?.map((company) => ({
              label: company.name,
              value: company.id,
            })) ?? []
          }
          onChange={handleClientChecked}
        />
      );
    },
    [companiesData, companiesLoading, companiesError, filtering.multipleCompanyIDs]
  );

  const contactFilterOptions = useMemo(() => {
    if (!companiesData) {
      return [];
    }

    if (filtering.companyId) {
      return company?.contacts.map(({ firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: `${firstName} ${lastName}`,
      }));
    }

    if (filtering.multipleCompanyIDs && filtering.multipleCompanyIDs.length > 0) {
      let contacts = [];
      filtering.multipleCompanyIDs.forEach((filteredCompanyId) => {
        const companyContacts = companiesData
          ?.find((company) => company.id === filteredCompanyId)
          .contacts.map(({ firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
            value: `${firstName} ${lastName}`,
          }));
        contacts = contacts.concat(companyContacts);
      });
      return contacts;
    }

    return companiesData?.flatMap(({ contacts }) =>
      contacts.flatMap(({ firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: `${firstName} ${lastName}`,
      }))
    );
  }, [companiesData, filtering.companyId, filtering.multipleCompanyIDs, company]);

  const ContactFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <SingleChoiceFilter
          id={id}
          value={filtering.contactName}
          loading={false}
          error={companiesError}
          options={contactFilterOptions ?? []}
          onChange={(selectedContactName) =>
            filtering.setContactName((prevState) =>
              prevState == selectedContactName ? null : selectedContactName
            )
          }
        />
      );
    },
    [contactFilterOptions]
  );

  const DueAtFilter = useCallback(
    ({ column: { id } }) => {
      return (
        <DateFilter
          id={id}
          value={filtering.dueAt}
          onChange={(selectedDate) =>
            filtering.setDueAt((prevState) => (prevState == selectedDate ? null : selectedDate))
          }
        />
      );
    },
    [filtering.dueAt]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }) => (
          <Link style={{ color: "inherit" }} to={`/opportunities/${value}/update`}>
            {value}
          </Link>
        ),
        minWidth: "30px",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row, value }: Cell<Opportunity>) => (
          <Link style={{ color: "inherit" }} to={`/opportunities/${row.original.id}/update`}>
            {value}
          </Link>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: StatusFilter,
        minWidth: "100px",
        Cell: ({ value }) => capitalCase(value),
      },
      { Header: "Address", accessor: "addressLine1" },
      { Header: "City", accessor: "addressCity" },
      { Header: "Zip", accessor: "addressZip" },
      { Header: "Company Name", accessor: "company.name", Filter: CompanyFilter },
      {
        Header: "Contact Name",
        accessor: "contact.firstName",
        Cell: ({ row, value }: Cell<Opportunity>) =>
          row.original.contact.firstName + " " + row.original.contact.lastName,
        Filter: ContactFilter,
      },
      { Header: "Contact Phone", accessor: "contact.phone", disableSortBy: true },
      {
        Header: "Due Date",
        accessor: "dueAt",
        Filter: DueAtFilter,
        minWidth: "100px",
      },
      {
        Header: "Product Scope",
        accessor: "products",
        disableSortBy: true,
        Cell: ({ row, value }: Cell<Opportunity>) => (
          <ul>
            {row.original.products.map((product) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
        ),
      },
      {
        Header: "Sales Person",
        accessor: "user.firstName",
        Cell: ({ row, value }: Cell<Opportunity>) =>
          row.original.user ? row.original.user.firstName + " " + row.original.user.lastName : "",
        disableSortBy: true,
        Filter: SalesPersonFilter,
      },
      {
        Header: "Notes",
        accessor: "notes",
        disableSortBy: true,
        Cell: ({ value }: Cell<Opportunity>) => shortenLongDescription(stripHtml(value), 75),
      },
      {
        align: "center",
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: OpportunityActionsCell,
      },
    ];
  }, [StatusFilter, SalesPersonFilter, CompanyFilter, ContactFilter, DueAtFilter]);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    sorting,
    paginatorInfo,
    refetch,
    searchControl,
  } as const;
}
