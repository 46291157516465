import { Company, Contact, Proposal, ProposalAddress } from "generated/graphql";
import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Job } from "generated/graphql";
import ProjectManagerSelection from "./ProjectManagerSelection";
import NotesSection from "./NotesSection";
import ChangeOrders from "modules/proposals/ChangeOrders/ChangeOrders";
import BillingStatusSelection from "./BillingStatusSelection";
import { useState } from "react";
import ProjectFileExplorerInput from "modules/ProjectFiles/ProjectFileExplorerInput";
import useUpdateJobProjectFileFolderId from "hooks/jobs/useUpdateJobProjectFileFolderId";

export default function CompanySection({
  company,
  contact,
  address,
  projectManager,
  billingStatus,
  jobId,
  notes,
  datePushedToQuickbooks,
  stageName,
  jobNumber,
  proposal,
  changeOrders,
  projectFilesFolderId,
}: {
  company: Company;
  contact: Contact;
  address: ProposalAddress;
  projectManager: Job["userId"];
  billingStatus: Job["billingStatus"];
  jobId: Job["id"];
  notes: Job["notes"];
  datePushedToQuickbooks: Job["datePushedToQuickbooks"];
  stageName: string;
  jobNumber: Job["externalId"];
  proposal: Partial<Proposal>;
  changeOrders: Job["changeOrders"];
  projectFilesFolderId?: Job["projectFilesFolderId"];
}) {
  const [folderId, setFolderId] = useState(projectFilesFolderId);
  const [updateJobProjectFileFolderId] = useUpdateJobProjectFileFolderId();

  const handleProjectFilesFolderSelect = (folderId: string) => {
    setFolderId(folderId);
    updateJobProjectFileFolderId({
      variables: {
        id: jobId,
        projectFilesFolderId: folderId,
      },
    });
  };

  return (
    <Card>
      <MDBox p={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography py={2}>
              <b>Job Number:</b> {jobNumber}
            </MDTypography>

            <MDTypography>
              <b>Company name:</b> {company.name}
            </MDTypography>

            <MDTypography sx={{whiteSpace: 'normal', wordWrap: 'break-word', display: 'block'}}>
              <b>Stage name:</b> {stageName}
            </MDTypography>

            <MDTypography sx={{whiteSpace: 'normal', wordWrap: 'break-word', display: 'block'}}>
              <b>Address: </b>
              {address.line1}, {address.line2 ? `${address.line2}` : ""}
              {address.zip}, {address.city}, {address.state}, {address.country}
            </MDTypography>
            <MDTypography variant="h3" pt={3}>
              Contact info
            </MDTypography>
            <MDTypography>
              Name: {contact.firstName} {contact.lastName}
            </MDTypography>
            <MDTypography> Email: {contact.email}</MDTypography>
            <MDTypography pb={4}> Phone: {contact.phone}</MDTypography>
            <ProjectManagerSelection jobId={jobId} projectManager={projectManager} />
            <br />
            <BillingStatusSelection jobId={jobId} billingStatus={billingStatus} />
            <MDBox style={{ display: "flex" }} py={3}>
              <MDBox style={{ width: "10vw" }}>Project Files </MDBox>
              <ProjectFileExplorerInput
                submitFn={handleProjectFilesFolderSelect}
                folderId={folderId}
                sx={{ borderBottom: "1px solid #d2d6da", width: "7vw" }}
              />
            </MDBox>

            {datePushedToQuickbooks && (
              <MDTypography variant="body2" mt={1}>
                Pushed to Quickbooks: {datePushedToQuickbooks}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox
              component="img"
              src={`https://cdn.bitumio.com/${address.mapImage}`}
              alt="location"
              width="100%"
            />
          </Grid>
        </Grid>
      </MDBox>
      <Divider />
      <MDBox p={4}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <ChangeOrders changeOrders={changeOrders} />
          </Grid>
        </Grid>
      </MDBox>
      <Divider />
      <NotesSection notes={notes} jobId={jobId} />
    </Card>
  );
}
