import { ProductMeasurementMaterial, ProductType } from "generated/graphql";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";

export const getMaterialCostTypeOptions = (type) => {
  switch (type) {
    case ProductType.MICROSURFACING:
    case ProductType.SLURRY_SEAL:
    case ProductType.EXCAVATE_OUT:
    case ProductType.ASPHALT:
    case ProductType.GRADING:
      return [{ label: "Ton", value: ProductMeasurementMaterial.TON }];
    case ProductType.CRACK_SEAL:
    case ProductType.MASTIC:
      return [{ label: "Block", value: ProductMeasurementMaterial.BLOCK }];
    case ProductType.STRIPING:
    case ProductType.SEALCOAT:
      return [
        { label: "Gallon", value: ProductMeasurementMaterial.GALLON },
        { label: "Ton", value: ProductMeasurementMaterial.TON },
      ];
    case ProductType.CONCRETE_FLATWORK:
    case ProductType.CONCRETE_CURB_OR_GUTTER:
    case ProductType.CURB_WALL:
    case ProductType.CONCRETE_WATERWAY:
    case ProductType.CONCRETE_FOOTING:
    case ProductType.CONCRETE_WALL:
    case ProductType.CONCRETE_COLUMN:
      return [{ label: "Yard", value: ProductMeasurementMaterial.YARD }];
    case ProductType.GENERIC:
      return enumToValueOptions(ProductMeasurementMaterial);
    default:
      return [];
  }
};
