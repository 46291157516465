import {
  AdditionalCostFeeType,
  ProposalAdditionalCost,
  ProposalProduct,
  ProposalTransferTruck,
} from "generated/graphql";
import { mapObjIndexed, mergeRight, omit } from "ramda";
import removeKeysInArray from "utils/object/removeKeysInArray";
import setNullValuesTo from "utils/object/setNullValuesTo";
import getAttributesToSkip from "utils/products/getAttributesToSkip";
import * as z from "zod";

export const AddTruckToProposalProductInputResolver = z.object({
  proposalProductId: z.string().min(1, "Proposal product Id is required"),
  transferTruckId: z.string().min(1, "Proposal product Id is required"),
  truckingProductionRate: z.coerce.number().min(0).optional(),
  rate: z.coerce.number().min(0).optional(),
});

export const UpdateProposalProductInputResolver = z.object({
  /* end local values */
  id: z.string().min(1, "Product Id is required"),
  jobSize: z.number().positive().or(z.literal("")),
  mapData: z.string().optional(),
  notes: z.string().optional(),
  transferTruckRoundTripTime: z.literal("").or(z.number().positive().optional()),
  transferTruckLoadTimeBuffer: z.literal("").or(z.number().positive().optional()),
  transferTruckUnloadTimeBuffer: z.literal("").or(z.number().positive().optional()),
  overallTotalOverride: z.literal("").or(z.number().positive().optional()),
  jobDurationDaysOverride: z.literal("").or(z.number().positive().optional()),
  jobDurationOverride: z.literal("").or(z.number().positive().optional()),
  roundTripTravelTimeHours: z.literal("").or(z.number().positive().optional()),
  profitPercentageOverride: z.literal("").or(z.number().positive().optional()),
  material: z.object({
    unitPrice: z.literal("").or(z.number().optional()),
    markup: z.literal("").or(z.number().positive()),
    overheadPercentage: z.literal("").or(z.number().optional()),
    indirectPercentage: z.literal("").or(z.number().optional()),
    statementOfWork: z.string().min(1),
    costMaterial: z.literal("").or(z.number().optional()),
    dailyUnitsComplete: z.literal("").or(z.number().positive()),
    materialNeeded: z.literal("").or(z.number().optional()),
    attributes: z
      .object({
        applicationRate: z.number().optional(),
        asphaltDepth: z.number().optional(),
        crackDepth: z.number().optional(),
        crackWidth: z.number().optional(),
        curbHeight: z.number().optional(),
        curbWidth: z.number().optional(),
        gutterHeight: z.number().optional(),
        gutterWidth: z.number().optional(),
        lineWidth: z.number().optional(),
        overbandThickness: z.number().optional(),
        overbandWidth: z.number().optional(),
        poundsPerBlock: z.number().optional(),
        poundsPerGallon: z.number().optional(),
        transferTruckLoadTime: z.number().optional(),
        transferTruckUnloadTime: z.number().optional(),
        height: z.number().optional(),
        width: z.number().optional(),
        diameter: z.number().optional(),
        depth: z.number().optional(),
      })
      .optional(),
    tonConversionFactor: z.literal("").or(z.number()).optional(),
  }),
  equipmentPieces: z.array(
    z.object({
      id: z.string().min(1, "Equipment Piece Id is Required"),
      quantity: z.number().optional(),
      rate: z.number().optional(),
      timeOnJob: z.coerce.number().min(0).optional(),
      timeTravel: z.coerce.number().min(0).optional(),
    })
  ),
  additionalCosts: z.array(
    z.object({
      id: z.string().min(1, "Additional Cost Id is Required"),
      cost: z.number(),
      feeAmount: z.number(),
      quantity: z.number().min(1),
      showOnProposal: z.boolean(),
    })
  ),
  crews: z.array(
    z.object({
      id: z.string().min(1, "Crew Id is Required"),
      rate: z.number().optional(),
      timeOnjob: z.coerce.number().min(0).optional(),
      timeTravel: z.coerce.number().min(0).optional(),
    })
  ),
  plant: z
    .object({
      rateOnWeight: z.boolean(),
    })
    .optional(),
  transferTrucks: z.array(
    z.object({
      plantTransferTruckId: z.string().min(1, "Plant Transfer Truck Id is Required"),
      quantity: z.number(),
      rate: z.number().optional(),
    })
  ),
  subcontractorCost: z.literal("").or(z.number().positive().optional()),
  subcontractorName: z.string().optional(),
  subcontractorProposalId: z.string().optional(),
});

export type UpdateProposalProductInputSchema = z.infer<typeof UpdateProposalProductInputResolver>;
export type AddTruckToProposalProductInputSchema = z.infer<
  typeof AddTruckToProposalProductInputResolver
>;

export type AdditionalCost = UpdateProposalProductInputSchema["additionalCosts"];

export const convertHrsToMins = (field) =>
  typeof field === "number" ? +(field * 60).toFixed(2) : null;

export const getDefaultValues = ({
  product,
  transferTrucks,
  averageWorkingHoursPerDay,
}: {
  product: ProposalProduct;
  transferTrucks: ProposalProduct["transferTrucks"];
  averageWorkingHoursPerDay: number;
}): UpdateProposalProductInputSchema => {
  const attributesToSkip = getAttributesToSkip(product.material.organizationProductTypeName);

  const productTransferTrucksMap = product.transferTrucks.reduce(
    (acc: Record<ProposalTransferTruck["transferTruckId"], ProposalTransferTruck>, val) => {
      return {
        ...acc,
        [val.plantTransferTruckId]: val,
      };
    },
    {}
  );

  // const mergedAttributes = mergeRight(
  //   product.material.attributes,
  //   product.material.attributesOverride
  // );

  const attributesWithoutTypename = omit(["__typename"])(product.material.attributes);
  const attributes = removeKeysInArray(attributesToSkip)(attributesWithoutTypename);
  const attributesOverrides = Object.keys(attributes).reduce((acc, val) => {
    return {
      ...acc,
      [val]: product.material.attributesOverride?.[val] ?? null,
    };
  }, {});

  const handleTimeDisplay = (field) =>
    typeof field === "number" ? +(field / 3600).toFixed(2) : null;

  return {
    id: product.id,
    jobSize: product.jobSize ?? null,
    mapData: product.mapData,
    notes: product.notes ?? null,
    transferTruckRoundTripTime: product.transferTruckRoundTripTimeOverride ?? null,
    transferTruckLoadTimeBuffer: convertHrsToMins(product.transferTruckLoadTimeBuffer),
    transferTruckUnloadTimeBuffer: convertHrsToMins(product.transferTruckUnloadTimeBuffer),
    overallTotalOverride: product.overallTotalOverride ? +product.overallTotalOverride : null,
    profitPercentageOverride: product.profitPercentageOverride ?? null,
    jobDurationDaysOverride: product.jobDurationDaysOverride ?? null,
    jobDurationOverride: product.jobDurationOverride ?? null,
    roundTripTravelTimeHours: product.roundTripTravelTimeHours ?? null,
    material: {
      markup: product.material.markupPercentageOverride ?? null,
      costMaterial: product.material.costMaterialOverride ?? null,
      dailyUnitsComplete: product.material.dailyUnitsCompleteOverride
        ? +product.material.dailyUnitsCompleteOverride
        : null,
      materialNeeded: product?.material?.materialNeededOverride ?? null,
      attributes: attributesOverrides,
      statementOfWork:
        product.material.statementOfWorkOverride ?? product.material.statementOfWork ?? null,
      tonConversionFactor: product.material.tonConversionFactorOverride ?? null,
      overheadPercentage: product?.material?.overheadPercentageOverride ?? null,
      indirectPercentage: product?.material?.indirectPercentageOverride ?? null,
      unitPrice: product?.material?.unitPriceOverride ?? product?.material?.unitPrice ?? null,
    },
    equipmentPieces:
      product.equipmentPieces?.map((equipmentPiece) => ({
        id: equipmentPiece.id,
        rate: equipmentPiece.rateOverride ?? null,
        quantity: equipmentPiece.quantityOverride ?? null,
        timeTravel: handleTimeDisplay(equipmentPiece.timeTravelOverride),
        timeOnJob: handleTimeDisplay(equipmentPiece.timeOnjobOverride),
      })) ?? [],
    additionalCosts:
      product.additionalCosts?.map((additionalCost) => ({
        id: additionalCost.id,
        cost: additionalCost.costOverride ?? null,
        feeAmount: additionalCost.feeAmountOverride ?? null,
        quantity: additionalCost.quantityOverride ?? null,
        showOnProposal: additionalCost.showOnProposal ?? false,
      })) ?? [],
    crews:
      product.crews?.map((crew) => ({
        id: crew.id,
        rate: crew.rateOverride ?? null,
        timeOnjob: handleTimeDisplay(crew.timeOnjobOverride),
        timeTravel: handleTimeDisplay(crew.timeTravelOverride),
      })) ?? [],
    plant: {
      rateOnWeight: product.plantProduct?.plant?.rateOnWeight ?? false,
    },
    subcontractorCost: product?.subcontractorCost ? +product?.subcontractorCost : 0,
    subcontractorName: product?.subcontractorName ?? "",
    subcontractorProposalId: product?.subcontractorProposalId ?? "",

    transferTrucks: transferTrucks.map((plantTransferTruck) => ({
      plantTransferTruckId: plantTransferTruck.id,
      quantity: productTransferTrucksMap[plantTransferTruck.id]?.quantity ?? 0,
      // rate depends on wether it's by weight or hourly
      rate: product.plantProduct?.plant?.rateOnWeight
        ? // Rate Weight
          productTransferTrucksMap[plantTransferTruck.id]?.rateTonOverride ?? null
        : // Rate Hourly
          productTransferTrucksMap[plantTransferTruck.id]?.rateHourlyOverride ?? null,
    })),
  };
};
