import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "width",
    label: "Width in inches",
  },
  {
    name: "depth",
    label: "Depth in inches",
  },
];

export default function ConcreteFootingForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
