import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "diameter",
    label: "Diameter in Inches",
  },
  {
    name: "height",
    label: "Height in Feet",
  },
];

export default function ConcreteColumnForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
