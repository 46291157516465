import { FormHelperText, Grid } from "@mui/material";
import { capitalCase } from "change-case";
import { OrganizationProductType, ProductType } from "generated/graphql";
import { useFormContext } from "react-hook-form";
import CrackSealCalculateAreaModal from "../modals/CrackSealCalculateAreaModal";
import GradingCalculateAreaModal from "../modals/GradingCalculateAreaModal";
import MasticCalculateAreaModal from "../modals/MasticCalculateAreaModal";
import AsphaltAttributesForm from "./AsphaltAttributesForm";
import ConcreteCurbOrGutterForm from "./ConcreteCurbOrGutterForm";
import ConcreteFlatworkForm from "./ConcreteFlatworkForm";
import ConcreteWaterwayForm from "./ConcreteWaterwayForm";
import CrackSealForm from "./CrackSealForm";
import CurbWallForm from "./CurbWallForm";
import ExcavateOutForm from "./ExcavateOutForm";
import GradingForm from "./GradingForm";
import MasticForm from "./MasticForm";
import MicrosurfacingForm from "./MicrosurfacingForm";
import SealcoatForm from "./SealcoatForm";
import SlurrySealForm from "./SlurrySealForm";
import StripingForm from "./StripingForm";
import ConcreteFootingForm from "./ConcreteFootingForm";
import ConcreteWallForm from "./ConcreteWallForm";
import ConcreteColumnForm from "./ConcreteColumnForm";

const attributesFormsMapping = {
  [ProductType.ASPHALT]: AsphaltAttributesForm,
  [ProductType.CONCRETE_CURB_OR_GUTTER]: ConcreteCurbOrGutterForm,
  [ProductType.CONCRETE_FLATWORK]: ConcreteFlatworkForm,
  [ProductType.CONCRETE_WATERWAY]: ConcreteWaterwayForm,
  [ProductType.CRACK_SEAL]: CrackSealForm,
  [ProductType.CURB_WALL]: CurbWallForm,
  [ProductType.EXCAVATE_OUT]: ExcavateOutForm,
  [ProductType.GRADING]: GradingForm,
  [ProductType.MASTIC]: MasticForm,
  [ProductType.MICROSURFACING]: MicrosurfacingForm,
  [ProductType.SEALCOAT]: SealcoatForm,
  [ProductType.SLURRY_SEAL]: SlurrySealForm,
  [ProductType.STRIPING]: StripingForm,
  [ProductType.CONCRETE_FOOTING]: ConcreteFootingForm,
  [ProductType.CONCRETE_WALL]: ConcreteWallForm,
  [ProductType.CONCRETE_COLUMN]: ConcreteColumnForm,
};

const modalFormsMapping = {
  [ProductType.CRACK_SEAL]: CrackSealCalculateAreaModal,
  [ProductType.GRADING]: GradingCalculateAreaModal,
  [ProductType.MASTIC]: MasticCalculateAreaModal,
};

const areaCoverageInternalFields = [
  "poundsPerGallon",
  "poundsPerBlock",
  "poundsPerCubicYard",
  "poundsPerGallon",
  "poundsPerBlock",
];

export default function ProductTypeFields({
  productType,
}: {
  productType: OrganizationProductType;
}) {
  const {
    formState: { errors },
  } = useFormContext();
  const Component = attributesFormsMapping[productType.type];
  const Modal = modalFormsMapping[productType.type];

  return (
    <>
      {Component && <Component productType={productType} />}
      {Modal && (
        <Grid item xs={12}>
          <Modal productType={productType} />
          {errors.attributes &&
            Object.keys(errors.attributes)
              .filter((errorKey) => areaCoverageInternalFields.includes(errorKey))
              .map((errorKey) => (
                <FormHelperText key={errorKey} error>
                  {capitalCase(errorKey)}: {errors.attributes[errorKey].message}
                </FormHelperText>
              ))}
        </Grid>
      )}
    </>
  );
}
