import { ProductMeasurementJob, ProductType } from "generated/graphql";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { capitalCase } from "change-case";

export const jobMeasurementPlurals = {
  [ProductMeasurementJob.LINEAR_FOOT]: "Linear Feet",
  [ProductMeasurementJob.SQUARE_FOOT]: "Square Feet",
  [ProductMeasurementJob.SQUARE_YARD]: "Square Yards",
  [ProductMeasurementJob.UNIT]: "Units",
};

export const jobMeasurementPounds = {
  [ProductMeasurementJob.LINEAR_FOOT]: "Pounds per Linear Foot",
  [ProductMeasurementJob.SQUARE_FOOT]: "Pounds per Square Foot",
  [ProductMeasurementJob.SQUARE_YARD]: "Pounds per Square Yard",
};

export default function getJobMeasurementMethodOptions(type) {
  switch (type) {
    case ProductType.CRACK_SEAL:
    case ProductType.STRIPING:
    case ProductType.MASTIC:
    case ProductType.CONCRETE_CURB_OR_GUTTER:
    case ProductType.CURB_WALL:
    case ProductType.CONCRETE_WATERWAY:
    case ProductType.CONCRETE_FOOTING:
    case ProductType.CONCRETE_WALL:
      return [
        {
          label: capitalCase(ProductMeasurementJob.LINEAR_FOOT),
          value: ProductMeasurementJob.LINEAR_FOOT,
        },
      ];

    case ProductType.CONCRETE_COLUMN:
      return [
        {
          label: `Per ${capitalCase(ProductMeasurementJob.UNIT)}`,
          value: ProductMeasurementJob.UNIT,
        },
      ];

    case ProductType.SEALCOAT:
    case ProductType.MICROSURFACING:
    case ProductType.SLURRY_SEAL:
    case ProductType.EXCAVATE_OUT:
    case ProductType.ASPHALT:
    case ProductType.CONCRETE_FLATWORK:
    case ProductType.GRADING:
      return [
        {
          label: capitalCase(ProductMeasurementJob.SQUARE_FOOT),
          value: ProductMeasurementJob.SQUARE_FOOT,
        },
        {
          label: capitalCase(ProductMeasurementJob.SQUARE_YARD),
          value: ProductMeasurementJob.SQUARE_YARD,
        },
        {
          label: `Per ${capitalCase(ProductMeasurementJob.UNIT)}`,
          value: ProductMeasurementJob.UNIT,
        },
      ];

    case ProductType.GENERIC:
      return [
        ...enumToValueOptions(ProductMeasurementJob).filter(
          (x) => x.value !== ProductMeasurementJob.UNIT
        ),
        {
          label: `Per ${capitalCase(ProductMeasurementJob.UNIT)}`,
          value: ProductMeasurementJob.UNIT,
        },
      ];
    default:
      return [];
  }
}

export const applicationRateLabelMappings = {
  [ProductType.ASPHALT]: "Asphalt Depth in Inches",
  ["Asphalt"]: "Asphalt Depth in Inches",
  [ProductType.SLURRY_SEAL]: "Application Rate in Pounds",
  ["Slurry Seal"]: "Application Rate in Pounds per",
  [ProductType.SEALCOAT]: "Application Rate in Inches",
  ["Sealcoat"]: "Application Rate in Inches",
  [ProductType.EXCAVATE_OUT]: "Excavation Depth in Inches",
  ["Excavate Out"]: "Excavation Depth in Inches",
  [ProductType.STRIPING]: "Application Rate in Inches",
  ["Striping"]: "Application Rate in Inches",
};
