import CreateProductAttributesFormFields from "./CreateProductAttributesFormFields";

const fields = [
  {
    name: "width",
    label: "Width in inches",
  },
  {
    name: "height",
    label: "Height in feet",
  },
];

export default function ConcreteWallForm() {
  return <CreateProductAttributesFormFields fields={fields} />;
}
